body {
  margin: 0;
  font-family: 'Pretendard', 'Pretendard Variable', -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  box-sizing: border-box;
  line-height: 24px;
  height: 100vh;
}

pre {
  font-family: 'Pretendard', 'Pretendard Variable';
  white-space: pre-wrap;
}

p {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-17 10:16:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@page {
  size: A4 portrait;
  margin-top: 60px;
  margin-bottom: 60px;
}

@media print {
  #root {
    visibility: hidden;
    height: 0;
  }

  [role='presentation'] {
    visibility: hidden;
    height: 0;
  }

  .print-qr-container,
  .print-stim-agree-container,
  .print-sleep-container {
    visibility: visible !important;
    display: block;
  }

  .print-sleep-container {
    width: 1180px !important;
  }
  .print-sleep-chart canvas {
    width: 1000px !important;
    height: 202px !important;
  }
  .print-sleep-chart {
    padding-left: 36px;
    height: 202px !important;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-questionnaire * {
    font-size: 16px !important;
  }
}

/**
 * ----------------------------------------
 * react-datepicker custom css
 * ----------------------------------------
 */
.react-datepicker {
  font-family: Pretendard, 'Pretendard Variable';
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 110px !important;
  padding: 8px 0;
  font-size: 16px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  padding: 16px;
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  width: 12px;
  height: 12px;
  border-color: #333;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 18px;
}

.react-datepicker__day {
  width: 40px;
  line-height: 40px;
}
.react-datepicker__day-name {
  width: 40px;
  line-height: 40px;
}
.react-datepicker__header--time {
  padding: 20px 16px;
}

/* day-name: 요일 */
.react-datepicker__day-name:nth-child(1) {
  color: #ff5555; /* 일요일 */
}

.react-datepicker__day:nth-child(1):not(.react-datepicker__day--disabled) {
  color: #ff5555; /* 일요일 */
}
